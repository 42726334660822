import React, {useContext} from "react";
import WindowSizeContext from "../../contexts/windowSizeContext";

export default () => {
  const windowSize = useContext(WindowSizeContext);

  if (typeof window === `undefined`) {
    return <></>;
  }

  return (
    <section className="">
      <div className="container pb-10 mx-auto">
        <div className="flex flex-wrap overflow-hidden">
          <div className="w-full overflow-hidden">
            <h3 className={`text-blue-500 ${windowSize.isLargeScreenView ? `text-left` : `text-center`}`}>The Comparison Company</h3>
            <p className={`${windowSize.isLargeScreenView ? `text-left` : `text-center`}`}>
              Many Americans are experiencing financial turmoil due to the COVID-19 pandemic, and unfortunately, a lot of the people being hit the hardest don't have a safety net in place. Millions of
              credit card customers don't have the means to pay their bills, and many households who have lost income fear they'll be almost $1,000 short on their monthly bills. 
            </p>
            <p className={`${windowSize.isLargeScreenView ? `text-left` : `text-center`}`}>At the Comparison Company, our comparison service helps to ease your financial burden.</p>

            <h3 className={`text-blue-500 mt-2 ${windowSize.isLargeScreenView ? `text-left` : `text-center`}`}>Are You Over-Extended Financially?</h3>
            <p className={`${windowSize.isLargeScreenView ? `text-left` : `text-center`}`}>
              We understand that average Americans in this day and age aren't frivolously spending their money, but we're also aware that—through no fault of their own—they might be unwittingly
              overpaying on basic utilities or unknowingly signing up for a loan or insurance that they can't truly afford.
            </p>
            <p className={`${windowSize.isLargeScreenView ? `text-left` : `text-center`}`}>
              That's why we developed the tools found on our website to help you compare basic home utility costs like electricity, water, cable, internet, natural gas, home insurance, car insurance,
              and more. We take the guesswork out of which provider to choose for which service by gathering all of the necessary information and presenting it in a clear way that enables you to make
              the best choice for your household.
            </p>

            <h3 className={`text-blue-500 mt-2 ${windowSize.isLargeScreenView ? `text-left` : `text-center`}`}>One-Stop Shop for Services, Products, and Utilities</h3>
            <p className={`${windowSize.isLargeScreenView ? `text-left` : `text-center`}`}>
              Want to shop around to lower the cost of your current home utilities? Looking for a personal, car, or home loan or wanting to refinance an existing loan? Whether you answered yes to
              these questions, you're in the market for insurance, or you need business products to help your small business grow, our comparison service is able to streamline the process.
            </p>
            <p className={`${windowSize.isLargeScreenView ? `text-left` : `text-center`}`}>
              Our comparison tool saves you time and money by doing the research and the work for you. We compare prices across 30 different services, products, and utilities and then provide the best
              results for each. At the Comparison Company, we empower you—the consumer—with the tools and knowledge necessary to control your budget and better manage your finances.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

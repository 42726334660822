export default {
  landingPageTestimonials: [
    {
      testimonial: "I was able to easily find a new price for my electricity supply and was able to arrange it with out dealing with lots of suppliers.",
      userName: "Clive Garcia",
      location: "Austin, Tx",
      rating: "4",
    },
    {
      testimonial:
        "I have used this app to find out how to refinance my student loan. I found it useful to have an easy to view comparison of the loan companies. It saved me switching between tabs in my browser.",
      userName: "Karen Allen",
      location: "Addison, TX",
      rating: "4",
    },
    {
      testimonial: "I hope that The Comparison Company continues to add more services and providers as I think it could be very useful. Will definitely bookmark.",
      userName: "Frank Williams",
      location: "Dallas, Tx",
      rating: "3",
    },
    {
      testimonial: "I found all the services I needed to start my own business when I was without a job. This is a seriously useful resource.",
      userName: "Lorraine Rodriguez",
      location: "Dallas, TX",
      rating: "4",
    },
  ],
  userProfileTestimonials: [
    {
      testimonial: "I was able to easily find a new price for my electricity supply and was able to arrange it with out dealing with lots of suppliers.",
      userName: "Clive Garcia",
      location: "Austin, Tx",
      rating: "4",
    },
    {
      testimonial:
        "I have used this app to find out how to refinance my student loan. I found it useful to have an easy to view comparison of the loan companies. It saved me switching between tabs in my browser.",
      userName: "Karen Allen",
      location: "Addison, TX",
      rating: "4",
    },
    {
      testimonial: "I hope that The Comparison Company continues to add more services and providers as I think it could be very useful. Will definitely bookmark.",
      userName: "Frank Williams",
      location: "Dallas, Tx",
      rating: "3",
    },
    {
      testimonial: "I found all the services I needed to start my own business when I was without a job. This is a seriously useful resource.",
      userName: "Lorraine Rodriguez",
      location: "Dallas, TX",
      rating: "4",
    },
  ],
};

import React from "react";
import { getUser } from "../utils/auth";
import View from "./View";
import UserRefererSection from "../components/UserProfileReferSection";
import UserProfileTestimonials from "./UserProfileTestimonials";
import UserProfileUserData from "./UserProfileUserData";
import UserProfileCopy from "./UserProfileCopy";

const Profile = () => {
  const user = getUser();
  const googleAccountId = process.env.GATSBY_GADWORDS_KEY;

  typeof window !== "undefined" && window.gtag("event", "conversion", { send_to: `${googleAccountId}/8h82CMWb5fEBEMHI77MC` });

  return (
    <View>
      <UserProfileUserData user={user} />
      <UserProfileCopy />
      <UserRefererSection />
      <UserProfileTestimonials />
    </View>
  );
};

export default Profile;
